const barringtonMenu = [
  {
    url: "/admin/home",
    name: "Overview",
    slug: "home",
    icon: "HomeIcon",
    role: ["CaseOfficer", "Administrator"]
  },
  {
    header: "Manage Customer",
    icon: "PackageIcon",
    items:[
      {
        url: "/admin/Customers",
        name: "Customers",
        slug: "customer",
        icon: "CircleIcon",
        role:  ["Administrator","CaseOfficer"]
      },
    ]
  },
  {
    header: "Maintenance",
    icon: "PackageIcon",
    items:[
      {
        url: "/user",
        name: "Users",
        slug: "user",
        icon: "UserIcon",
        role:  ["Administrator","CaseOfficer"]
      },
      {
        url: "/client-department",
        name: "Department",
        slug: "user",
        icon: "CircleIcon",
        role:  ["Administrator","CaseOfficer"]
      },
      {
        url: "/clearance-type",
        name: "Clearance",
        slug: "user",
        icon: "CircleIcon",
        role:  ["Administrator","CaseOfficer"]
      },
    ]
  },
  {
    header: "Reports",
    icon: "PackageIcon",
    items:[
      {
        url: "/Reports/RequestReceived",
        name: "Request Received",
        slug: "user",
        icon: "CircleIcon",
        role:  ["Administrator","CaseOfficer"]
      },
      {
        url: "/Reports/CompletedJobs",
        name: "Completed Jobs",
        slug: "user",
        icon: "CircleIcon",
        role:  ["Administrator","CaseOfficer"]
      },
      {
        url: "/Reports/NonCompletedJobs",
        name: "Non-Completed Jobs",
        slug: "user",
        icon: "CircleIcon",
        role:  ["Administrator","CaseOfficer"]
      },
    ]
  },
];

export const menuItems = {
  barringtonMenu
};
