<template>
<footer class="the-footer flex-wrap justify-between" :class="classes">
    <p>
        <span>COPYRIGHT &copy; </span>
        <span>{{ new Date().getFullYear() }} </span>
        <a href="https://www.holtestate.com.au/bhp" target="_blank" rel="nofollow">Barrington Group Australia</a>
        <span class="hidden sm:inline-block">, All Rights Reserved</span>
    </p>
</footer>
</template>

<script>
export default {
    name: 'the-footer',
    props: {
        classes: {
            type: String
        }
    },
    components: {},
    data() {
        return {}
    },
    methods: {}
}
</script>
